@import '../../node_modules/@angular/material/theming';

@include mat-core();

@mixin theming($theme, $typography: null) {
    @include angular-material-theme($theme);
    @if $typography {
        @include angular-material-typography($typography);
    }
    /* include mixin from component, if desired*/
}