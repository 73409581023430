@import '../../node_modules/@angular/material/theming';

@import './base.theme';

$primary: mat-palette($mat-blue, 500);
$accent:  mat-palette($mat-orange, 500);
$warn:    mat-palette($mat-yellow, 700);
$theme:   mat-light-theme($primary, $accent, $warn);
/*
$custom-typography: mat-typography-config(
  $font-family: 'Roboto, monospace',
  $headline: mat-typography-level(26px, 40px, 700),
  $title: mat-typography-level(24px, 40px, 600),
  $body-1: mat-typography-level(16px, 24px, 500),
);*/

@include theming($theme);