/* You can add global styles to this file, and also import other style files */


@import '../node_modules/@angular/material/theming';
@import './print.css';
/*
@import '../node_modules/quill/dist/quill.core.css';
@import '../node_modules/quill/dist/quill.bubble.css';
@import '../node_modules/quill/dist/quill.snow.css';*/
    
html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.mat-toolbar-white{
    background-color: #fff;
}

.mat-dialog-container{
    padding: 0px !important;
}
/*
.modalBackDrop{
    //background-color: #fff;
}*/
.spiner-button{
    height: 32px;
    width: 82px
}

.bloc-search {
    background: white;
    border-radius: 20px!important;
    padding: 0px 0px 0px 15px!important;
    margin-right: 8px;
    
}

.bloc-search input { background: none; border:none; height: 35px; text-align: center; outline: none; margin: 0px 20px 0px 0px;}

.bloc-search-input-margin { margin: 0px 20px 0px 0px;}


.mat-padding {
    display: block;
    padding: 10px 10px;
}
.mat-padding-small{
    padding: 5px;
}

.input-search {
    
}
/*
     {
    
    width: 75%;
    border-radius: 3px;
    
    text-align: center;
}
*/
.logo{
    margin: 0px 16px;
}
.button-search{ 
    width: 10%;
    border:none;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
}
.search-block{
    width: 60%;
    border: 1px solid #d7d7d7;
    background: #fff;
    border-radius: 3px;
}
mat-drawer{
    color:rgb(128, 128, 128);
    max-width: 20%;
    min-width: 250px;
}

.mat-expansion-panel:not([class*=mat-elevation-z]){
    box-shadow: none;
}
.mat-drawer-container,.mat-drawer-content{
    background: #fff;
}
.mat-drawer-side{
    border-right: none;
}

.mat-accordion .mat-expansion-panel:first-of-type {
    border-top-right-radius: 0px;
    border-top-left-radius: 0px;
}
mat-panel-title span {
    padding:2px 8px ;
    margin-left: 8px;
}
.mat-drawer-container[_ngcontent-c0] {
    background: #f5f5f5;
}


/* tabStyleDefault*/

.mat-button-rounded{
    //border-radius: 20px 0px 20px 0px !important;
    border-radius: 20px!important;
    padding: 0px 14px 0px 8px !important;
}

/*Ligne en dessous de textfield*/
.mat-form-field-appearance-legacy .mat-form-field-underline {
    background-color: rgba(0, 0, 0, 0.1);
}

/**/
.action-column {
   width:50px;
}
/**/
.q-editor {
    background: #f9f9f9;
    border-radius: 8px;
}
.q-editor .ql-toolbar.ql-snow {
    border: none !important;
}
.q-editor .ql-container.ql-snow {
    border: none !important;
    max-height: 100px!important;
}
/**/


.md-overflow{
    overflow: scroll;
}

.mat-autocomplete-result{
    box-shadow:none!important;
    background: #fbfafa;
    border-left: 1px solid #d0d7ff;
    border-bottom: 1px solid #d0d7ff;
    border-right: 1px solid #d0d7ff;
    margin-left: -1px;
}



///EXPLORE PAGE////@extend
mat-drawer{
    border-right: 1px solid #fff;
    width: 270px;
}

.mat-drawer-side.mat-drawer-end {
    border-left: solid 1px #fff;
    border-right: none;
}

.container{
    width: 351px;
    padding: 20px;
}
.map{
    border-radius: 20px;
}

.card-margin-right{
    margin-right: 20px;
    
}
.container-margin-top-bottom {
    margin-top: 20px;
    margin-bottom: 20px;
}
.slider-width{
    width: calc(96% - 31px);
    margin: 0px 20px;
}
.span{
    padding: 10px;
    display: block;
}

//Notifications Tab Container

//@extendinscription-form
.inscription-form{

}

.inscription-form .bloc-input{
    //background: rgb(247, 247, 247);
    border-radius: 8px 8px 0 0;
}
.mat-form-field-appearance-fill .mat-form-field-ripple {
    bottom: 0;
    height: 2px;
}

.inscription-form .mat-form-field-underline{
    bottom: inherit;
    /*background: #fff!important;*/
}
.inscription-form .mat-form-field-underline {
    &::before {
        background: #fff!important;

    }
    &:hover {
        background: #fff!important;
    }
}

.inscription-form .mat-form-field-wrapper {
    padding-bottom: 0em;
}

.inscription-form .mat-form-field-appearance-fill .mat-form-field-flex {
    border-radius: 0px 0px 0 0;
    padding: .75em .75em 0 .75em;
    background-color: inherit;
}

.mat-form-field.mat-form-field-invalid .mat-form-field-ripple, .mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent {
    background-color:inherit;
}
.mat-card{
    border-radius: 0px!important;
    padding: 0px!important;
}
/////////////////////// Customization des tabs

/////////////////////////////////
.label-badge {
    background-color: #d00c0c;
    padding: 3px;
    font-size: 10px;
    color: white;
    border-radius: 4px;
    position: relative;
    top: -12px;
    right: 0px;
}
//////////////// GESTION DES THEMES /////////////////////
.ql-toolbar.ql-snow {
    background: #fff !important;
}

.defaultTheme{
    @import './themes/fuel.theme';
}

.st_renew {
    color: #c12e64;
}

.stick-blue{padding: 4px 13px; background: #3f51b5;  color: #fff; border-radius: 5px; font-size: 0.7em;}
.stick-red{padding: 4px 13px; background: #c12e64; color: #fff; border-radius: 5px; font-size: 0.7em;}
.stick-grey{padding: 4px 13px; background: #ccc;  color: #000; border-radius: 5px; font-size: 0.8em;}
.stick-green{padding: 4px 13px; background: #007a15; color: #fff; border-radius: 5px; font-size: 0.8em;}
.stick-orange{padding: 4px 13px; background: #d5673d; color: #fff; border-radius: 5px; font-size: 0.8em;}

.mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
    line-height: 30px;
}

.startBackDrop{background: rgb(0 0 0 / 75%);}
#startId{
    box-shadow: none!important;
    background: none!important;
    color: #fff;
}

/* PDF VIEWER */
.pdfViewer .page {
    border: 1px solid #eee!important;
    box-shadow: 10px 13px 20px -12px rgba(0,0,0,0.56);
}

.pdfViewer.removePageBorders .page {
    margin: 25px auto 10px!important;
    /* border: none; */
}

.current-demande{
    background-color: #fffdd3d9 !important;
}

.current-demande-up{
    background-color: rgb(255 211 211 / 85%) !important;
}
.current-demande-down{
    background-color: rgb(211 241 255 / 85%) !important;
}